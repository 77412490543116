@font-face {
    font-family: 'Muller';
    src: url(../fonts/MullerRegular.woff2) format('woff2'), url(../fonts/MullerRegular.woff) format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Muller Bold';
    src: url(../fonts/MullerBold.woff2) format('woff2'), url(../fonts/MullerBold.woff) format('woff');
    font-display: swap;
}

html, body {
    font-family: 'Muller', sans-serif;
    color: #000000;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
}

html {
    overflow-x: hidden;
}

section {
    margin: 0;
    padding: 6rem 0;
}

.row {
    margin: 0;
}

a {
    color: #fabf40;
}

.dark-background,
.dark-background h2,
.dark-background h3,
.dark-background h4,
.dark-background-without-brush,
.dark-background-without-brush h2 {
    background-color: #1d1a24;
    color: #FFFFFF;
}

.dark-background-without-brush {
    box-shadow: 20px 0px 40px rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.yellow-background {
    background-color: #fabf40;
    color: #FFFFFF;
}

.white-background {
    background-color: #FFFFFF;
    color: #1d1a24;
}

.black-color {
    color: #1d1a24;
}

.index-text {
    font-size: 1.6rem;
    text-align: center;
}

.footer {
    width: 100%;
    height: 30px;
}


.yellow-text {
    color: #fabf40;
}
.uppercase-text {
    text-transform: uppercase;
}

.fw-700 {
    font-weight: 700;
}

.heading {
    background-image: url("../img/header-background.png");
    background-repeat:no-repeat;
    background-size:contain;
    height: 100vh;
}

.contact-form-small {
    z-index: 1;
    position: relative;
    width: 100%;
    padding: 50px;
    color: #54616E;
    background-color: #FFF;
    -webkit-box-shadow: 1px 11px 68px -20px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 11px 68px -20px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 11px 68px -20px rgba(0, 0, 0, 0.75);
}

.contact-form-small h3 {
    font-size: 1.4rem;
    text-decoration: none;
}

.btn-primary {
    background-color: #fabf40;
    border-color: #fabd00;
    -webkit-box-shadow: 0 0 60px rgba(250, 191, 64, 0.45);
    -moz-box-shadow: 0 0 60px rgba(250, 191, 64, 0.45);
    box-shadow: 0 0 60px rgba(250, 191, 64, 0.45);
    -o-box-shadow: 10px -10px  rgba(0,0,0,0.6);
    border-radius: 50px;
    padding-left: 40px;
    padding-right: 40px;
    color: #1d1a23;
}

.btn-primary:hover, .btn-primary:active{
    background-color: #faaf06;
}

.btn-primary:disabled{
    background-color: #fae062;
}

.form-control:focus {
    border-color: #fabf40 !important;
}

h1, h2, h3 {
    text-transform: none;
    letter-spacing: 0;
}

h1 {
    font-weight: 300;
    font-size: 3.5rem;
    line-height: 1.2;
    color: #fff;
}

h1 span {
    font-weight: normal;
    font-size: 6rem;
    display: block;
}

h2 {
    font-size: 4rem;
    font-weight: bold;
    font-family: 'Muller Bold', sans-serif;
    margin-bottom: 40px;
}

h3 {
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Muller Bold', sans-serif;
    margin-bottom: 20px;
}

h4 {
    font-size: 1.5rem;
    font-family: 'Muller', sans-serif;
    margin-bottom: 20px;
    text-transform: none;
    color: #fabf40 !important;
}

.index {
    background-size: contain !important;
    background: #1d1a23 url("../img/background-lg.png") no-repeat bottom;;
}

.index::before,
.index::after {
    content: "";
    position: absolute;
    height: 10%;
    width: 100%;
    left: 0;
    background: url(../img/brush.png) repeat-x left bottom;
}

.index::before {
    top: -2rem;
    transform: rotate(180deg);
}

.index::after {
    bottom: -2rem;
}

.highlight {
    text-transform: uppercase;
    font-weight: bold;
    color: #fabf40;
}

.advantage-highlight {
    font-weight: bold;
    color: #FFFFFF;
    font-style: italic;
    font-family: "Playfair Display", serif;
}

.yellow-background .highlight {
    color: #FFFFFF;
}

.form-control {
    display: initial;
}

.questions-img {
    width: 100%;
    height: auto;
}

.dark-background::before,
.dark-background::after {
    content: "";
    position: absolute;
    height: 20%;
    width: 100%;
    left: 0;
    background: url(../img/brush.png) repeat-x left bottom;
}

.dark-background::after {
    bottom: -2rem;
}

.dark-background::before {
    top: -2rem;
    transform: rotate(180deg);
}

.dark-button {
    background-color: #1d1a24;
    color: #FFFFFF;
    -webkit-box-shadow: 0 0 60px rgba(0, 0, 10, 0.45);
    -moz-box-shadow: 0 0 60px rgba(0, 0, 10, 0.45);
    box-shadow: 0 0 60px rgba(0, 0, 10, 0.45);
}

.dark-button:hover {
    background-color: #2a2731;
}

.advantage {
    height: 100px;
    vertical-align: center;
}

.review {
    background-color: #FFFFFF;
    box-shadow: 0 0 60px rgba(250, 250, 250, 0.45);
}

.review-logo {
    width: 100%;
}

.flipful-review {
    border-left: 3px solid #5bc15b;
    color: #1d1a24;
}

.smartwill-review {
    border-left: 3px solid rgba(24,137,197,1);
    color: #1d1a24;
}

.teirauto-review {
    border-left: 3px solid #596088;
    color: #1d1a24;
}

.review-quote {
    opacity: 0.1;
}

.review-quote-bottom {
    bottom: 0;
    right: 10px;
}

.review-quote-top {
    top: 0;
    left: 10px;
}

.review-micron-logo {
    width: auto;
    height: 30px;
    float: right;
}

.tab-head {
    cursor: pointer;
}

.item-head.active {
    color: #fabf40;
}

.tab-head:hover {
    color: #fabf40;
}

.item-head {
    z-index: 2;
}

.item-head::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: #fff;
    bottom: -20px;
    /*right: -2rem;*/
    opacity: 0.5;
}

.item-head::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    bottom: -24px;
    /*left: 2rem;*/
    box-shadow: 0 0 15px;
    z-index: 2;
}

.active:before {
    background: #fabf40;
    width: 13px;
    height: 13px;
    bottom: -26px;
}

.item-head:last-child:after  {
    content: '';
    height: 0;
    width: 0;
}

ul {
    list-style: none;
}

.checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    /*top: 0;*/
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

.project-type:hover .checkbox ~ .checkmark {
    background-color: #fabf40;
}

.project-type .checkbox:checked ~ .checkmark {
    background-color: #fabf40;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.project-type .checkbox:checked ~ .checkmark:after {
    display: block;
}

.project-type {
    cursor: pointer;
}

.project-type .checkmark:after {
    left: 9px;
    top: 5px;
    width: 7px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.contact-form-big {
    -webkit-box-shadow: 1px 11px 68px -20px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 11px 68px -20px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 11px 68px -20px rgba(0, 0, 0, 0.75);
}

.form-confirm-privacy {
    font-size: 10px;
    color: #666;
}

.error-message {
    color: red;
    font-size: 12px;
}

.about-us-item {
    cursor: pointer;
}

.about-us-item.active {
    color: #fabf40;
}
.about-us-item.active .about-us-name {
    text-decoration: none;
}

.about-us-item:hover {
    color: #fabf40;
}

.about-us-number {
    border: 1px solid;
    font-size: 60px;
    width: 70%;
    margin-left: 15%;
}

.about-us-name {
    text-decoration: underline;
}

h2 .number {
    font-size: 10rem;
    color: #fabf40;
    line-height: 1;
}

.about-us-header {
    line-height: 1;
    margin-top: 10px;
}

.about-us-text-number {
    font-size: 20em;
    color: #FFFFFF;
    opacity: 0.8;
    position: absolute;
    right: 5%;
    bottom: 0;
    line-height: 1;
}

.about-us-text-heading {
    font-size: 3rem;
    font-family: "Muller Bold", serif;
    font-weight: bold;
    text-transform: uppercase;
}

.about-us-text-subheading {
    font-size: 1.5rem;
    font-family: "Muller", serif;
    z-index: 1;
}

.about-us-text-block {
    background-color: #f2f2f2;
}

.about-us-text-block ul{
    padding-left: 0;
}

.about-us-text {
    z-index: 1;
}

ul li {
    position: relative;
    margin: 0.5rem 0;
    list-style: none;
    padding-left: 2rem;
    line-height: 1.4;
}

ul li::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 1rem;
    height: 1px;
    top: 10px;
    background: #1d1a23;
}

.mobile {
    display: block;
}

.desktop {
    display: block;
}

.prices-heading-div {
    border-left: 2px solid #fabf40;
}

.price-item-header{
    font-size: 1.8rem;
}

.price-number-div {
    font-family: "Playfair Display", serif;
    font-style: italic;
    color: #ffaa00;
    font-size: 1.2rem;
}

.price-number {
    font-size: 2.5rem;
    line-height: 1;
}

.price-body {
    font-size: 0.8rem;
    font-weight: 400;
}

.price-item-img {
    width: 100%;
    height: 100%;
}

.contact-info a{
    font-size: 1.5rem;
    text-decoration: underline;
}

.team-name {
    position: absolute;
    top: 1rem;
    padding: 1rem 4rem;
    font-size: 1.2rem;
    line-height: 40px;
    z-index: 2;
}

.team-name .social-link {
    color: #FFFFFF;
}

.team-name.right {
    right: 0;
}
.social-link {
    color: #1d1a23;
}

.social-link .fa {
    font-size: 40px;
}

.team-section {
    padding-bottom: 0;
}

.phone {
    color: #1d1a23;
}

.header .responsive-text {
    font-size: 1.8rem;
}

.review-div h3 {
    background-color: #FFFFFF;
    color: #1d1a23;
}

.review-text {
    display: contents;
}

button {
    z-index: 2;
}

.team-img {
    position: relative;
    bottom: -10px;
    z-index: 4;
    width: 75%;
}

.text-success {
    color: #3ca861;
}

.team-name a:hover {
    color: #ffffff;
}

.form-control {
    padding: .75rem 0.5rem;
}

.fa-facebook-square {
    background-image: url("../img/icons/facebook.png") ;
    background-repeat: no-repeat;
    background-size: cover;
    width: 50px;
    height: 50px;
}

.fa-instagram-square {
    background-image: url("../img/icons/instagram.png") ;
    background-repeat: no-repeat;
    background-size: cover;
    width: 50px;
    height: 50px;
}

.fa-linkedin-square {
    background-image: url("../img/icons/linkedin.png") ;
    background-repeat: no-repeat;
    background-size: cover;
    width: 50px;
    height: 50px;
}

.about-us-text-number {
    display: block;
}


@media only screen and (max-width: 1200px) {
    h1 {
        font-size: 3.5rem;
    }

    h1 span {
        font-size: 4rem;
    }

    .header {
        height: 80px;
    }

    .heading {
        height: calc(100vh - 100px);
    }

    h2 {
        font-size: 3rem;
    }

    h2>span {
        font-size: 3.5rem;
    }

    .team-name {
        position: relative;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    h1 {
        font-size: 3.5rem;
    }

    h1 span {
        font-size: 5rem;
    }

    /*.mobile {*/
        /*display: none;*/
    /*}*/
}

@media only screen and (max-width: 992px) {
    h1 {
        font-size: 4rem;
    }

    h1 span {
        font-size: 4.5rem;
    }

    .about-us-number {
        width: 50%;
        margin-left: 25%;
    }

    ul {
        padding: 0;
    }

    .index-text {
        font-size: 1.4rem;
    }

    .heading {
        height: 100vw;
    }

    .header {
        height: 70px;
    }

    h2 {
        font-size: 3rem;
    }

    h2>span {
        font-size: 3.5rem;
    }

    .about-us-number {
        border: none;
        font-size: 16px;
        display: inline;
        margin-right: 5px;
        margin-left: 0 !important;
    }

    .about-us-name {
        display: inline;
        margin-left: 0 !important;
    }

    h2 .number {
        font-size: 6rem;
    }

    .about-us-item {
        text-align: left !important;
    }

    .team-img {
        width: 100%;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    h1 {
        font-size: 3.5rem;
    }

    h1 span {
        font-size: 4rem;
    }

    .heading {
        height: calc(100vh - 100px);
    }

    .mobile {
        display: none;
    }

    .team-name {
        position: relative;
    }

    .header-logo {
        width: 100%;
    }

    .header .responsive-text {
        font-size: 1.4rem;
    }

    .heading {
        background-image: none;
    }

    .header {
        height: 60px;
    }

    .index {
        background-size: contain !important;
        background: #1d1a23 url("../img/background-md.png") no-repeat bottom;;
    }

    section {
        padding: 4rem 0;
    }

    h2 {
        font-size: 2.8rem;
    }

    h2>span {
        font-size: 3.3rem;
    }

    .review-div {
        border: none;
    }

    .team-img {
        width: 100%;
    }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .heading {
        height: calc(100vh - 100px);
    }

    h1 {
        font-size: 3.5rem;
        margin-bottom: 30%;
    }

    h1 span {
        font-size: 3.1rem;
    }

    .desktop {
        display: none;
    }

    .index-text {
        font-size: 1.2rem;
    }

    .header .responsive-text {
        font-size: 1rem;
    }
    .header {
        height: 50px;
    }

    .index {
        background-size: contain !important;
        background: #1d1a23 url("../img/background-sm.png") no-repeat bottom;;
    }

    h2 {
        font-size: 2.5rem;
        margin-bottom: 15px;
    }

    h2 span {
        font-size: 3rem;
    }

    section {
        padding: 2rem 0;
    }

    h2 .number {
        font-size: 5rem;
    }

    .about-us-text-heading {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 400px) {
    .heading {
        height: calc(100vh - 100px);
    }

    h1 {
        font-size: 2.6rem;
        margin-bottom: 10%;
    }

    h1 span {
        font-size: 2.3rem;
    }

    .desktop {
        display: none;
    }

    .index-text {
        font-size: 1rem;
    }

    .header .responsive-text {
        font-size: 0.8rem;
    }

    .header {
        height: 40px;
    }

    .index {
        background-size: contain !important;
        background: #1d1a23 url("../img/background-sm.png") no-repeat bottom;;
    }

    h2 {
        font-size: 2rem;
    }

    h2 span {
        font-size: 2.2rem;
    }

    h3 {
        font-size: 1.5rem;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 350px) {
    h1 {
        font-size: 2.6rem;
        margin-bottom: 10%;
    }

    h1 span {
        font-size: 2.2rem;
    }
}

@media only screen and (max-height: 400px) {
    .index {
        background: #1d1a23;
    }

    .heading h1 {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .index::before {
        height: 20%;
    }
}
