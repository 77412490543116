.header-logo {
  width: 200px;
}

.header-social {
  padding-top: 2px;
}

.dropdown-menu {
  width: auto;
  min-width: auto;
  padding-top: 0;
  padding-bottom: 0;
}
